<template>
  <h1 class="animateIn"><span class="back" @click="this.$router.back();">
      <fa icon="fa-solid fa-caret-left" />
    </span> Leerling: {{ student.name }}</h1><br>

  <ul class="nav nav-tabs">
    <li class="nav-item studentTab">
      <a @click="tab = 'progress'" class="nav-link" :class="{ 'active': tab == 'progress' }">
        <fa icon="fa-solid fa-graduation-cap" style="color:orange;" /> Voortgang
      </a>
    </li>
    <li class="nav-item studentTab">
      <a @click="tab = 'movement'" class="nav-link" :class="{ 'active': tab == 'movement' }">
        <fa icon="fa-solid fa-person-running" style="color:blueviolet;" /> Beweging
      </a>
    </li>
    <li class="nav-item studentTab">
      <a @click="tab = 'playtime'" class="nav-link" :class="{ 'active': tab == 'playtime' }">
        <fa icon="fa-solid fa-clock" style="color:rgb(226, 43, 119);" /> Actieve speeltijd
      </a>
    </li>
    <li class="nav-item studentTab">
      <a @click="tab = 'student'" class="nav-link" :class="{ 'active': tab == 'student' }">
        <fa icon="fa-solid fa-id-card" style="color:rgb(29, 119, 255);" /> Gegevens
      </a>
    </li>
  </ul>

  <br>

  <div v-if="student">
    <div v-if="tab == 'student'" class="smoothContainer">
      <div class="row">
        <h5>Gegevens</h5>
        <div class="col-lg-6 col-sm-12">
          <div class="form-floating mb-2">
            <input type="text" v-model="student.name" class="form-control" id="floatingInputName"
              ref="floatingInputName" placeholder="Leerling naam">
            <label for="floatingInputName">Naam</label>
          </div>
          <div class="form-floating mb-2">
            <input type="date" v-model="student.birthday" class="form-control" placeholder="Verjaardag"
              id="floatingInputDate">
            <label for="floatingInputDate">Verjaardag</label>
          </div>
          <div class="form-floating mb-2">
            <input type="number" min="0" step="1" v-model="student.height" class="form-control" placeholder="Lengte"
              id="floatingHeight">
            <label for="floatingHeight">Lengte (cm)</label>
          </div>
          <select class="form-select" id="handednessSelect" v-model="student.righthanded">
            <option :value="true" selected>Rechtshandig</option>
            <option :value="false">Linkshandig</option>
          </select>
        </div>

        <div class="col-lg-6 col-sm-12 mt-lg-0 mt-sm-3">
          <div class="row">
            <div class="col-12 mb-2">
              <h6>
                <fa icon="fa-solid fa-up-long" style="color: purple;" /> Level: {{ student.level }}
              </h6>
            </div>

            <div class="col-12 mb-2">
              <h6>
                <fa icon="fa-solid fa-star" style="color: orange;" /> Sterren: {{  student.currency }}
              </h6>
            </div>

            <div class="col-12 mb-2">
              <h6>
                <fa icon="fa-solid fa-gift" style="color: gold;" /> Kistjes: {{ student.crates }}
              </h6>
            </div>

            <h6>
              <fa icon="fa-solid fa-mitten" style="color: rgb(0, 183, 255);" /> Spullen:
            </h6>
            <div class="input-group mb-2">
              <span class="unlock" v-if="!student.unlocks || student.unlocks.length == 0">Leeg</span>
              <span class="unlock" v-for="(unlock, index) in student.unlocks" v-bind:key="index">
                {{ unlock }}
              </span>
            </div>

            <h6>
              <fa icon="fa-solid fa-ribbon" style="color: purple;" /> Prestaties:
            </h6>
            <div class="input-group mb-2">
              <span class="unlock" v-if="!student.achievements || student.achievements.length == 0">Leeg</span>
                <span class="unlock" v-for="(achievement, index) in student.achievements" v-bind:key="index">
                {{ index }}: {{ achievement }}
                </span>
            </div>

          </div>
        </div>
      </div>
      <div class="nav justify-content-end">
        <button class="btn btn-danger m-2" @click="removeStudent">Leerling verwijderen</button>
        <router-link to="/students" class="text-decoration-none"><button type="button"
            class="btn btn-default m-2">Annuleren</button></router-link>
        <button type="button" v-if="!this.saving" class="btn btn-primary m-2" @click="saveStudent">Opslaan
        </button>
        <button type="button" v-if="this.saving" class="btn btn-primary m-2">Bezig... </button>
      </div>
    </div>

    <div v-if="tab == 'student' && this.$store.state.user.admin" class="smoothContainer admin">
      <div class="row">
        <h5>Admin opties</h5>
        <div class="col-lg-6 col-sm-12">
          
          <button class="btn btn-danger" @click="resetStudentProgress">
            Alle progressie {{ student.name }} resetten
          </button>
        </div>
        <div class="col-lg-6 col-sm-12">
          <div class="row">
            <div class="col-4">
              <h6>
                <fa icon="fa-solid fa-up-long fa-fw" style="color: purple;" /> Level
              </h6>
              <div class="input-group mb-2">
                <input type="number" min="1" max="100" step="1" v-model="student.level" class="form-control" placeholder="Level"
                  id="level">
              </div>
            </div>

            <div class="col-4">
              <h6>
                <fa icon="fa-solid fa-star" style="color: orange;" /> Sterren
              </h6>
              <div class="input-group mb-2">
                <input type="number" min="0" step="1" v-model="student.currency" class="form-control"
                  placeholder="Sterren" id="stars">
              </div>
            </div>

            <div class="col-4">
              <h6>
                <fa icon="fa-solid fa-gift" style="color: gold;" /> Kistjes
              </h6>
              <div class="input-group mb-2">
                <input type="number" min="0" step="1" v-model="student.crates" class="form-control"
                  placeholder="Kistjes" id="crates">
              </div>
            </div>

            <h6>
              <fa icon="fa-solid fa-mitten" style="color: rgb(0, 183, 255);" /> Spullen
            </h6>
            <div class="input-group mb-2">
              <span class="unlock" v-if="!student.unlocks || student.unlocks.length == 0">Leeg</span>
              <span class="unlock" v-for="(unlock, index) in student.unlocks" v-bind:key="index">
                {{ unlock }}
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div v-if="tab == 'progress'">
      <div v-if="loadingLines" class="smoothContainer">
        <LoadingSpinner :loading="loadingLines" />
      </div>
      <div v-if="!loadingLines">
        <LearnLine v-for="line in learningLines" :key="line.data.id" :name="line.data.name"
          :templates="line.templates" />
      </div>
    </div>

    <div v-else-if="tab == 'movement'" class="smoothContainer">
      <LoadingSpinner :loading="loadingActivities" />
      <div v-if="!activities || activities.length == 0">
        Nog geen activiteiten gevonden.
      </div>
      <div v-else-if="activities.length > 0">
        <StudentMovement :activities="activities" />
      </div>
    </div>

    <div v-else-if="tab == 'playtime'" class="smoothContainer">
      <LoadingSpinner :loading="loadingActivities" />
      <div v-if="!activities || activities.length == 0">
        Nog geen activiteiten gevonden.
      </div>
      <div v-else-if="activities.length > 0">
        <StudentPlayTime :activities="activities" />
      </div>
    </div>

    <div v-if="tab == 'progress'" class="smoothContainer">
      <LoadingSpinner :loading="loadingActivities" />
      <h5>Laatste activiteit</h5>
      <div v-if="!activities || activities.length == 0">
        '{{ student.name }}' heeft nog geen activiteiten gedaan. <br>
        <router-link to="/activities/normal">
          <a>
            Start een eerste activiteit
          </a>
        </router-link>
        in het activiteiten scherm.
      </div>
      <div v-else-if="activities.length > 0">
        <p v-for="(activity, activityIndex) in activities" :key="activityIndex">
          <a class="text-decoration-none" @click="openActivity(activity)">{{ formatActivity(activity) }}</a>
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import { auth, firebase, db, firestore } from '@/main';
import { router } from '@/router';
import LoadingSpinner from './Nested/LoadingSpinner.vue';

import LearnLine from './Student/LearnLine.vue';
import StudentMovement from './Student/StudentMovement.vue';
import StudentPlayTime from './Student/StudentPlayTime.vue';

export default {
  name: 'StudentModule',
  components: {
    LearnLine,
    LoadingSpinner,
    StudentMovement,
    StudentPlayTime
  },
  data() {
    return {
      tab: 'progress',
      saving: false,
      activities: null,
      loadingActivities: true,
      loadingLines: true,
      settingTemplates: [],
      learningLines: [],
      student: {
        name: "",
        birthday: "",
        righthanded: true,
      }
    };
  },
  mounted() {
    this.loadStudent();
  },
  methods: {
    openActivity(activity) {
      router.push('/activity/' + this.$route.params.studentId + '/' + this.student.name + '/' + activity.id);
    },
    formatActivity(activity) {
      let date = activity.started.toDate();
      let today = new Date();
      let isToday = date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear();

      let time = date.toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' });

      if (isToday) {
        return "Vandaag (" + time + ")";
      }
      let formatted = date.toLocaleDateString('nl-NL', { day: '2-digit', month: 'numeric', year: 'numeric' });

      return formatted;
    },
    async loadStudent() {
      try {
        const doc = await db.collection("/schools/" + this.$store.state.schoolId + "/students").doc(this.$route.params.studentId).get();
        this.student = doc.data();
        this.student.id = doc.uid;
        this.student.currency = this.student.currency || 0;
        this.student.crates = this.student.crates || 0;
        this.student.righthanded = this.student.righthanded !== undefined ? this.student.righthanded : true;
        this.student.level = this.student.level || 1;
        this.student.experience = this.student.experience || 0;
        this.student.settingTemplates;
        let birthday = firestore.Timestamp.now();
        if (this.student.birthday != null) {
          birthday = this.student.birthday;
        }
        let dateJs = birthday.toDate();
        let dateString = dateJs.toISOString().slice(0, 10);

        this.student.birthday = dateString;
        if (!this.student.height) {
          this.student.height = 0;
        }

        this.getAssignedSettingsTemplates(this.student);
        this.loadLastActivities(this.student);

        //Freshly generated student? Open student tab
        if (this.student.name == "Nieuwe student" || this.student.name == "") {
          this.tab = 'student';
        }
        //And focus on name form input field with id floatingInputName
        this.$nextTick(() => {
          if (this.$refs.floatingInputName) {
            this.$refs.floatingInputName.focus();
            this.$refs.floatingInputName.select();
          }
          if (this.$refs.floatingInputName) {
            this.$refs.floatingInputName.select();
          }
        });
      }
      catch (error) {
        console.error("Error getting student data: ", error);
      }
    },
    saveStudent() {
      let date = new Date(this.student.birthday);
      let timestamp = firestore.Timestamp.fromDate(date);
      this.saving = true;
      db.collection("/schools").doc(this.$store.state.schoolId).collection("students").doc(this.$route.params.studentId).update({
        name: this.student.name,
        birthday: timestamp,
        currency: this.student.currency,
        crates: this.student.crates,
        level: this.student.level,
        height: this.student.height,
        righthanded: this.student.righthanded,
        changedById: auth.currentUser.uid,
        changed: firestore.Timestamp.now()
      }, { merge: true })
        .then(() => {
          this.saving = false;
        });
    },
    loadLastActivities() {
      // Define a Firestore query to get the documents that have a 'started' field with a timestamp between the start and end of today
      db.collection('activities')
        .where('studentId', '==', this.$route.params.studentId)
        .orderBy('started', 'desc')
        .limit(5)
        .get()
        .then(querySnapshot => {
          let _activities = [];
          querySnapshot.forEach(doc => {
            let _activity = doc.data();
            _activity.id = doc.id;
            _activities.push(_activity);
          });

          this.activities = _activities;
          this.loadingActivities = false;
        })
        .catch(error => {
          console.error('Error getting documents:', error);
        });
    },
    async getAssignedSettingsTemplates(student) {
      if (!student.settingTemplateIds) {
        this.loadingLines = false;
        return;
      }

      const chunkedIds = this.chunkArray(student.settingTemplateIds, 10); // split into chunks of 10 (limit of firestore in array filter)
      const settingTemplates = [];

      await Promise.all(chunkedIds.map(async (chunk) => {
        const querySnapshot = await db.collection("/settingTemplates")
          .where(firebase.firestore.FieldPath.documentId(), "in", chunk)
          .get();

        querySnapshot.forEach((templateDoc) => {
          const template = templateDoc.data();
          template.id = templateDoc.id;
          template.goalIds = [];
          template.completed = false;
          template.loadingResults = true;

          settingTemplates.push(template);
        });
      }));

      this.settingTemplates = settingTemplates;
      //Sort templates by lineId
      this.settingTemplates.sort((a, b) => a.lineId - b.lineId);

      //Get learning lines under which the templates fall
      await this.getLearningLines();

      //Get saves first ( completed state etc, is fast )
      await this.getTemplateSaveGame();

      //Slower detailed results. TODO: Only get results for templates that are not completed.
      this.getTemplateResults();
    },
    async getTemplateSaveGame() {
      await Promise.all(this.settingTemplates.map(async (template) => {
        try {
          const docRef = await db.collection("/schools")
            .doc(this.$store.state.schoolId)
            .collection("students")
            .doc(this.$route.params.studentId)
            .collection("save")
            .doc(template.id)
            .get();

          if (docRef.exists) {
            let saveGame = docRef.data();
            saveGame.id = docRef.id;

            template.level = saveGame.level || 0;
            template.completed = saveGame.completed || false;
          }
          else {
            // Handle empty query result if needed
            console.log("No savegame for template yet");
          }
        } catch (error) {
          console.error('Error getting documents:', error);
        }
      }));
    },
    async getTemplateResults() {
      //Get last results for setting templates
      //Just count the result if 
      //We might want to get some sort of average here of last playsessions
      const studentId = this.$route.params.studentId;

      //From top to bottom, load results for each line templates
      for (const line of Object.values(this.learningLines)) {
        for (const template of line.templates) {
          template.loadingResults = true;
          try {
            const querySnapshotActivity = await db.collection('activities')
              .where('studentId', '==', studentId)
              .where('settingTemplateIds', 'array-contains', template.id)
              .where('state', '==', 2) // Done playing only
              .orderBy('started', 'desc')
              .limit(1)
              .get();

            if (!querySnapshotActivity.empty) {
              let _lastActivity = querySnapshotActivity.docs[0].data();
              _lastActivity.id = querySnapshotActivity.docs[0].id;
              template.lastActivity = _lastActivity;
            }
            else {
              // Handle empty query result if needed
              console.log("No activities found for template");
            }
          }
          catch (error) {
            console.error('Error getting documents:', error);
          }

          //And get all results for this activity
          if (!template.lastActivity) {
            template.loadingResults = false;
            continue;
          }
          template.lastActivity.results = [];
          try {
            const querySnapshotResult = await db.collection('activities')
              .doc(template.lastActivity.id)
              .collection('results')
              .where('settingTemplateId', '==', template.id)
              .get();

            if (!querySnapshotResult.empty) {
              querySnapshotResult.forEach((resultDoc) => {
                const result = resultDoc.data();
                result.id = resultDoc.id;
                //Parse logs into split items
                result.logs = this.parseLogs(result.log);

                template.lastActivity.results.push(result);
              });

              template.lastActivity.results.sort((a, b) => a.level - b.level);
            }
          }
          catch (error) {
            console.error('Error getting documents:', error);
          }
          template.loadingResults = false;
        }
      }
    },
    parseLogs(log) {
      let _logs = [];
      let _seperateLogs;

      if (!log.includes("{")) {
        //Legacy non JSON logs
        _seperateLogs = log.split(";");
        _seperateLogs.forEach((log) => {
          if (log.length > 3) {
            let _logParts = log.split(",");

            let correctBool = (_logParts[1] == "1");

            _logs.push({
              log: _logParts[0],
              positive: correctBool
            })
          }
        });
      }
      else {
        //JSON logs
        let json = JSON.parse(log);
        for (let i = 0; i < json.descriptions.length; i++) {
          const event = json.descriptions[i];
          const correctBool = json.correct[i];
          _logs.push({
            log: event,
            positive: correctBool
          })
        }
      }

      return _logs;
    },
    async getLearningLines() {
      //Create the list of lines using the template ids
      let lines = {};
      for (let i = 0; i < this.settingTemplates.length; i++) {
        const template = this.settingTemplates[i];
        const lineId = template.lineId;
        const subdomainId = template.subdomainId;
        const domainId = template.domainId;

        //Add line if id does not exist yet in dictionary
        if (!lines[lineId]) {
          console.log("Adding line " + lineId);
          lines[lineId] = {
            id: lineId,
            domainId: domainId,
            subdomainId: subdomainId,
            data: { name: "Data not found", id: lineId },
            templates: []
          };
        }
        lines[lineId].templates.push(template);
      }

      //Get all data for the learning lines
      const keys = Object.keys(lines).sort();
      const promises = keys.map(async (key) => {
        const line = lines[key];

        const doc = await db.collection("/domains")
          .doc(line.domainId)
          .collection("subdomains")
          .doc(line.subdomainId)
          .collection("lines")
          .doc(key)
          .get();

        lines[line.id].data = doc.data();
      });

      await Promise.all(promises);

      this.loadingLines = false;
      this.learningLines = lines;
    },
    chunkArray(arr, chunkSize) {
      const result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        result.push(chunk);
      }

      return result;
    },
    resetStudentProgress() {
      //Confirm
      if (!confirm("Weet je zeker dat je de voortgang van deze leerling wilt resetten?")) {
        return;
      }

      //Set unlocks=[] and activated=[] and currency=0 for student
      const studentRef = db.collection("/schools").doc(this.$store.state.schoolId).collection("students").doc(this.$route.params.studentId);
      const resetStudentPromise = studentRef.update({
        lastActivity: null,
        currency: 0,
        experience: 0,
        crates: 0,
        stars: 0,
        unlocks: [],
        activated: []
      });

      //Remove all student saves in studentId/save
      const savesRef = studentRef.collection("save");
      const deleteSavesPromise = savesRef.get().then((items) => {
        const deletePromises = items.docs.map((doc) => doc.ref.delete());
        return Promise.all(deletePromises);
      });

      //Remove activities
      const resultsRef = db.collection("activities").where("studentId", "==", this.$route.params.studentId);

      //Remove all activities/{resultId}/results collections
      const deleteResultsPromise = resultsRef.get().then((items) => {
        const deletePromises = items.docs.map((doc) => {
          console.log("Deleting results for activity: " + doc.id);
          return db.collection("activities").doc(this.$route.params.studentId).collection("results").get().then((results) => {
            const deleteResultPromises = results.docs.map((result) => result.ref.delete());
            return Promise.all(deleteResultPromises);
          });
        });
        return Promise.all(deletePromises);
      });

      //Remove all activities with this studentId
      const activitiesRef = db.collection("activities").where("studentId", "==", this.$route.params.studentId);
      const deleteActivitiesPromise = activitiesRef.get().then((items) => {
        console.log("Deleting activities for student: " + this.$route.params.studentId);
        const deletePromises = items.docs.map((doc) => doc.ref.delete());
        return Promise.all(deletePromises);
      });

      Promise.all([resetStudentPromise, deleteSavesPromise, deleteActivitiesPromise, deleteResultsPromise])
        .then(() => {
          console.log("Student successfully reset!");
          this.loadStudent();
        })
        .catch((error) => {
          console.error("Error resetting student: ", error);
          this.loadStudent();
        });
    },
    removeStudent() {
      if (confirm("Weet je zeker dat je deze leerling wilt verwijderen")) {
        console.log("Removing student");
        db.collection("/schools/" + this.$store.state.schoolId + "/students").doc(this.$route.params.studentId).delete().then(() => {
          console.log("Student successfully deleted!");
          router.push('/students');
        }).catch((error) => {
          console.error("Error removing document: ", error);
          router.push('/students');
        });
      }
    },
  }
}
</script>

<style>
.goals {
  table-layout: fixed;
}

.unlock {
  border: 1px solid rgb(187, 187, 187);
  padding: 6px;
  margin: 2px !important;
}

.studentTab {

  margin-right: 5px;
  border-radius: 15px 15px 0 0;

}
</style>